import React, { useState, useEffect, useRef } from "react"
import {
    Row,
    Col,
    Modal,
    Label,
    Card,
    TabContent,
    TabPane,
    NavLink,
    NavItem,
    CardText,
    Nav,
    Table,
    Button,
    Input,
    Badge,
    UncontrolledTooltip,
    FormGroup,
    ButtonGroup,
} from "reactstrap"
import "./payment.css"
import { Link } from "react-router-dom"
import classnames from "classnames"
import { Formik, Field, Form, FastField } from "formik"

import NumberFormat from "react-number-format"
import Select from "react-select"

import { useDispatch } from "react-redux"
import {
    showSweetAlertDialog,
    hideSweetAlertDialog,
    showToast,
} from "store/layout/actions"

import { getById as getByIdSalesOrderService } from "services/vitta-core/sales-order"
import {
    search as searchSalesOrderPaymentService,
    create as createSalesOrderPaymentService,
    cancelPayment as cancelPaymentSalesOrderPaymentService,
    update as updateSalesOrderPaymentService,
} from "services/vitta-core/sales-order-payment"
import {create as createApprovalRequest } from "services/vitta-core/approval-request"

import SALES_ORDER_STATUS from "../../../utils/consts/sales-orders-status"
import SALES_ORDERS_PAYMENT_STATUS from "../../../utils/consts/sales-orders-payment-status"
import APPROVAL_REQUEST_REASON from "../../../utils/consts/approval-requests-reason-types"
import CARD_FLAGS from "utils/consts/card-flags"
import AUTTAR_TEF from "utils/consts/auttar-tef"
import TRANSACTION_MODES from "utils/consts/transaction-mode"

import QRCode from "react-qr-code"
import logoPix from "../../../assets/images/logo-pix.png"

import { showPixQrCode } from "services/vitta-tef/pix"
import { closePinPadConnection } from "services/vitta-tef/connection"
import {
    checkTransactionUpdates,
    changeTypeDiscountsApplied,
} from "services/vitta-core/sales-order-payment"
import SERVICES_TYPES from "utils/consts/services-types"
import HandleSelectItemsToPayModal from "../Registration/Odontologic/PayOdontologicItems/HandleSelectItemsToPayModal"
import FINANCIAL_PAYMENT_METHODS from "utils/consts/financial-payment-methods"
import PaymentCancellationModal from "./components/PaymentCancellationModal"
import { useSelector } from "react-redux"
import * as Sentry from "@sentry/react"
import ZOOP_SDK_PAYMENT_TYPES from "utils/consts/zoop-sdk-payment-types"
import CheckPaymentLoading from "./ModalCardPayments/CheckPaymentLoading"
//import ModalCardPayments from "./ModalCardPayments"
import ModalCardPayments from "./ModalCardPayments/indexEmbeded"
import { isSameDay, parseISO } from "date-fns";
const TotalValueInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    const [total, setTotal] = useState(0)

    useEffect(() => {
        let total_calc = 0

        total_calc +=
            props.formValues.money_value +
            props.formValues.cc_value +
            props.formValues.cd_value +
            props.formValues.financial_value

        setTotal(total_calc)
    }, [props.formValues])

    return (
        <NumberFormat
            {...field}
            {...props}
            className={props.className + " bg-light"}
            value={total}
            onChange={e => {
                /*only to not dispatch this event*/
            }}
        />
    )
}

const CurrencyInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <NumberFormat
        {...field}
        onChange={e => {
            /*only to not dispatch this event*/
        }}
        onValueChange={option => {
            setFieldValue(field.name, option.floatValue)
        }}
        {...props}
    />
)

const TextInput = ({
    field, // { name, value, onChange, onBlur }
    ...props
}) => <Input type="text" rows="1" {...field} {...props} />

const ReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => (
    <Select
        {...field}
        {...props}
        onChange={option => setFieldValue(field.name, option)}
    />
)

const optionsParcels = [
    { label: "1x", value: 1 },
    { label: "2x", value: 2 },
    { label: "3x", value: 3 },
    { label: "4x", value: 4 },
    { label: "5x", value: 5 },
    { label: "6x", value: 6 },
    { label: "7x", value: 7 },
    { label: "8x", value: 8 },
    { label: "9x", value: 9 },
    { label: "10x", value: 10 },
    { label: "11x", value: 11 },
    { label: "12x", value: 12 },
]

const optionsFlags = [
    {
        label: "American Express",
        value: CARD_FLAGS.AMERICAN_EXPRESS,
    },
    {
        label: "Elo",
        value: CARD_FLAGS.ELO,
    },
    {
        label: "Master Card",
        value: CARD_FLAGS.MASTER_CARD,
    },
    {
        label: "Visa",
        value: CARD_FLAGS.VISA,
    },
    {
        label: "Hiper Card",
        value: CARD_FLAGS.HIPERCARD,
    },
]

const PAYMENT_FORMS_ELECTRONIC = ["PIX", "CC", "CD"]

const ModalPayment = ({
    show,
    data,
    subscriptionData,
    onClose,
    onCancelPayment,
}) => {
    const defaultValues = {
        patient_name: "",
        payer_name: data?.patient.name ? data?.patient.name : null,
        payer_cpf: data?.patient.cpf ? data?.patient.cpf : null,
        order_id: null,
        total_order: 0,
        total_paid: 0,
        money_value: 0,
        pix_value: 0,
        cc_value: 0,
        cc_parcels: { label: "1x", value: 1 },
        cc_nsu_code: null,
        cd_value: 0,
        cd_nsu_code: null,
        cc_card_flag: null,
        financial_value: 0,
        financial: null,
    }

    const defaultPageData = {
        loading: true,
        form_data: defaultValues,
        order_data: null,
        payments: [],
        onlyView: false,
    }

    const defaultPageControl = {
        operations: {},
        submiting: false,
        lastForceLoadData: null,
        clearPageOnLoadData: true,
        useTef: false,
        canUseTef: false,
    }

    const userId = useSelector(state => state.auth.access.user.id)

    const [pageControl, setPageControl] = useState(defaultPageControl)

    const [pageData, setPageData] = useState(defaultPageData)

    const [paymentActiveTab, setPaymentActiveTab] = useState("clean")

    const [autoPrintReceipt, setAutoPrintReceipt] = useState(false)

    const [showLoadingButtonPay, setShowLoadingButtonPay] = useState(false)

    const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(
        false
    )
    const [cancellationItem, setCancellationItem] = useState(null)

    const AccessRoles = useSelector(state => state.auth.access?.user?.access_roles)

    const checkCancelPaymentPermissions = (accessRoles) => {
        const priorityPermission = "cancel_sales_order_payment";
        const secondaryPermission = "request_cancel_sales_order_payment";
      
        let foundPermission = null;
      
        const hasPriorityPermission = accessRoles?.some((role) =>
          role.permissions?.some((permission) => {
            return permission.name === priorityPermission;
          })
        );
      
        if (hasPriorityPermission) {
          foundPermission = priorityPermission;
        } else {
          const hasSecondaryPermission = accessRoles?.some((role) =>
            role.permissions?.some((permission) => {
              return permission.name === secondaryPermission;
            })
          );
      
          if (hasSecondaryPermission) {
            foundPermission = secondaryPermission;
          }
        }
      
        return foundPermission; 
      };
      

    const openCancellationModal = item => {
        setCancellationItem(item)
        setIsCancellationModalOpen(true)
    }

    const checkIsSameDay = (createdAt) => {
        
        const createdAtDateFormatted = parseISO(createdAt);

        const currentDate = new Date();

        return isSameDay(createdAtDateFormatted, currentDate);

    }
    const handleConfirmCancellation = cancelReason => {
        if (cancellationItem) {

            const permission = checkCancelPaymentPermissions(AccessRoles);
            
            let transactionIsSameDay = checkIsSameDay(cancellationItem.created_at);

            let needCancelByCheckout = false;

            if(["CD", "CC"].includes(cancellationItem.pay_form_id) && cancellationItem.status == SALES_ORDERS_PAYMENT_STATUS.PAID && transactionIsSameDay && TRANSACTION_MODES.ELECTRONIC == cancellationItem.transaction_mode) {
                needCancelByCheckout = true;
            }

            if (permission === "cancel_sales_order_payment") {
                cancelPayment(cancellationItem, cancelReason, transactionIsSameDay);
            } else if (permission === "request_cancel_sales_order_payment" && !needCancelByCheckout) {
                requestCancelPayment(cancellationItem, cancelReason);
            } else {
                dispatch(
                    showSweetAlertDialog({
                        title: "Permissão não encontrada",
                        text: "Você não possui permissões para cancelar pagamentos diretamente ou solicitar aprovação de cancelamento. Solicite ao seu gestor.",
                        type: "error",
                        showConfirm: true,
                    })
                );
            }
    
            setIsCancellationModalOpen(false);
        }
    };

    const [controlOdontologicData, setControlOdontologicData] = useState({
        modal: {
            show: false,
            data: null,
        },
        selectedData: [],
        totalSelectedData: 0,
        haveToControl: false,
        haveToControlEdit: false,
    })

    const [controlModalCardPayments, setControlModalCardPayments] = useState({
        show: false,
        data: null,
        data_form: null,
        action: "make_payment",
    })

    const [
        controlCheckPaymentLoadingModal,
        setControlCheckPaymentLoadingModal,
    ] = useState({
        show: false,
    })
    const dispatch = useDispatch()

    const calcTotalPaid = payments => {
        let total_paid = 0
        for (let i = 0; i < payments.length; i++) {
            let usePay = payments[i]
            if (usePay.status == SALES_ORDERS_PAYMENT_STATUS.PAID) {
                total_paid += usePay.value
            }
        }

        return total_paid
    }

    useEffect(() => {
        let printReceipt = localStorage.getItem(
            "vittanet.sales_order_payment.auto_print"
        )
        setAutoPrintReceipt(printReceipt == "true")
    }, [])

    const changeAutoPrint = check => {
        setAutoPrintReceipt(check.target.checked)
        localStorage.setItem(
            "vittanet.sales_order_payment.auto_print",
            check.target.checked
        )
    }

    useEffect(() => {
        const loadData = async () => {
            //console.log("entrando no loaddata", pageControl);

            if (pageControl.clearPageOnLoadData) {
                setPageData(defaultPageData) //Para nao precisar limpar quando a tela já estiver aberta
                setPageControl(defaultPageControl)
                setPaymentActiveTab("clean")
            }

            if (data && data?.service_type == SERVICES_TYPES.DENTAL) {
                let setOdontologicData = { ...controlOdontologicData }
                setOdontologicData.haveToControl = true
                setOdontologicData.haveToControlEdit = false
                setControlOdontologicData(setOdontologicData)
            }

        
            try {
                let result = await getByIdSalesOrderService(data.id, {
                    expand_unit: true,
                    expand_revenue_split: true,
                })
                let salesOrder = result.doc
                //console.log("teste",salesOrder)
                let total_order = 0
                for (let i = 0; i < salesOrder.items.length; i++) {
                    let useItem = salesOrder.items[i]

                    total_order += useItem.quantity * useItem.price
                }

                let salesOrderPayments = await searchSalesOrderPaymentService(
                    { sales_order_id: data.id, order_by_created_at: "DESC" },
                    1,
                    50
                )

                let total_paid = calcTotalPaid(salesOrderPayments.data)

                let auxPageData = { ...pageData }

                let form_data = {
                    ...defaultValues,
                    patient_name: salesOrder.patient.name,
                    order_id: "#" + salesOrder.id,
                    total_order: total_order > 0 ? total_order / 100 : 0,
                    total_paid: total_paid > 0 ? total_paid / 100 : 0,
                }

                //Adicionando como padrão o valor que falta no campo de dinheiro. Solicitação Priscila Gestão Caixa Brasília
                //form_data.money_value = form_data.total_order - form_data.total_paid; //Removido

                auxPageData.order_data = salesOrder
                auxPageData.form_data = form_data
                auxPageData.payments = salesOrderPayments.data
                auxPageData.pay_forms = result.pay_forms
                auxPageData.onlyView =
                    salesOrder.status != SALES_ORDER_STATUS.AWAITING_PAYMENT &&
                    salesOrder.status != SALES_ORDER_STATUS.PARTIALLY_PAID
                auxPageData.loading = false
                setPageData(auxPageData)

                let orderAcceptTef = false

                // if (salesOrder?.unit?.electronic_vittapag_payment_status == 1) {
                //     orderAcceptTef = true

                  
                //     let unqualifiedProfessional = salesOrder?.items?.find(
                //         e =>
                //             e?.exec_professional &&
                //             e?.exec_professional
                //                 ?.electronic_vittapag_payment_status == 0
                //     )

                //     if (unqualifiedProfessional) {
                //         orderAcceptTef = false
                //     }
                // } else {}
            
                    if (salesOrder.unit?.electronic_payment_status == 1 || salesOrder?.unit?.electronic_vittapag_payment_status == 1) {
                        if (salesOrder.sales_order_revenue_split.length == 0) {
                            orderAcceptTef = true
                        } else {
                            for (
                                let i = 0;
                                i < salesOrder.sales_order_revenue_split.length;
                                i++
                            ) {
                                let useRev =
                                    salesOrder.sales_order_revenue_split[i]
                                if (
                                    useRev.unit_id == null &&
                                    useRev.professional_id != null
                                ) {
                                    if (
                                    useRev.professional &&
                                    (useRev.professional
                                            .electronic_payment_status == 1  || useRev?.professional?.electronic_vittapag_payment_status == 1) 
                                    ) {
                                        orderAcceptTef = true
                                    } else {
                                        orderAcceptTef = false
                                        break
                                    }
                                }
                            }
                        }
                    }
                

                let auxPageControl = { ...pageControl }
                auxPageControl.electronic_vittapag_payment_status = salesOrder?.unit?.electronic_vittapag_payment_status;
                auxPageControl.canUseTef = orderAcceptTef
                auxPageControl.useTef = auxPageControl.canUseTef

                return setPageControl(auxPageControl)
            } catch (e) {
                console.log(e)
                closeModal(pageControl.operations)
            }
        }

        if (data?.id) loadData()
    }, [data, pageControl.lastForceLoadData])

    const closeModal = data => {
        if (showLoadingButtonPay) return
        setPageControl(defaultPageControl) //Clear page control to clear page on open again
        onClose(data)
    }

    const cancelCardPayment = item => {
        const sales_order_payment_id = item?.id
        const action = "cancel_payment"
        const referenceId = item?.vittapag_id

        startFluxPayment(referenceId, action, sales_order_payment_id)
    }

    const cancelPayment = async (item, cancelReason, transactionIsSameDay = false) => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção ",
                text: "Tem certeza que deseja cancelar este Recebimento?",
                type: "warning",
                onConfirmAction: async () => {
                    if (
                        item.transaction_mode == TRANSACTION_MODES.ELECTRONIC &&
                        ["CD", "CC"].includes(item.pay_form_id) && transactionIsSameDay
                    ) {
                        if (item.status == SALES_ORDERS_PAYMENT_STATUS.PAID) {
                            // verificar se não tem alguma pendencia no back antes de abrir o checkout
                            const verifyVittapag = true
                            const payment = await confirmCancelPayment(
                                item,
                                cancelReason,
                                verifyVittapag
                            )

                          
                            if (payment?.id) {
                                cancelCardPayment(item)
                            }
                        } else {
                            confirmCancelPayment(item, cancelReason)
                        }
                    } else {
                        confirmCancelPayment(item, cancelReason)
                    }
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }
    const requestCancelPayment = async (item) => {
        dispatch(
            showSweetAlertDialog({
            title: "Atenção",
            text: "Seu usuário não possui permissão para cancelar pagamentos diretamente. Deseja solicitar a aprovação de um supervisor?",
            type: "warning",
            onConfirmAction: async () => {
                try {
                    const entity = APPROVAL_REQUEST_REASON.SALES_ORDER_PAYMENTS.ENTITY_NAME
                    const reason = APPROVAL_REQUEST_REASON.SALES_ORDER_PAYMENTS.REASONS.CANCEL_PAYMENT

                let approvalRequest = await createApprovalRequest(item.id, entity, reason )

                if (approvalRequest) {
                    dispatch(
                    showSweetAlertDialog({
                        title: "Solicitação de Cancelamento Criada com Sucesso!",
                        text: "Verifique com o supervisor.",
                        type: "success",
                        showConfirm: true,
                    })
                    )
                }
                } catch (error) {
                let textError = "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

                if (error?.response?.status == 403) {
                    textError = "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
                } else if (error?.response?.data) {
                    switch (error.response.data.error_code) {
                    case "cash_control_not_open":
                        textError = "Necessário abrir o caixa para cancelar o pagamento."
                        break
                    case "approval-request-already-created":
                        textError = "Já existe uma solicitação de cancelamento aberta para este pagamento."
                        break
                    }
                }

                return dispatch(
                    showSweetAlertDialog({
                    title: "Erro",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                    closeOnClickOutside: false,
                    })
                )
                }
            },
            showCancel: true,
            showConfirm: true,
            cancelBtnText: "Não",
            confirmBtnText: "Sim",
            })
        )
    }

    const showPixPaymentMonitor = async paymentCreated => {
        return new Promise(async (resolve, reject) => {
            var pinPadOk = true
            var intervalCheckTransaction = null
            var transactionChecks = 0
            var transactionCheckTimeInterval = 7000

            var closePixMonitor = (reloadComponentData = false) => {
                try {
                    resolve()

                    dispatch(hideSweetAlertDialog({}))

                    clearInterval(intervalCheckTransaction) //parar busca da transação

                    setTimeout(async () => {
                        try {
                            await closePinPadConnection() //Fechar conexão com o pinpad
                        } catch (e) {
                            console.log(e)
                        }
                    }, 5)

                    if (reloadComponentData) {
                        //Para atualizar os pagamentos
                        let auxPageControl = { ...pageControl }
                        auxPageControl.lastForceLoadData = new Date().getTime()
                        auxPageControl.clearPageOnLoadData = false
                        setPageControl(auxPageControl)
                    }
                } catch (e) {
                    console.log(e)
                }
            }

            dispatch(
                showSweetAlertDialog({
                    title: "Carregando QrCode...",
                    text: (
                        <>
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>{" "}
                            Aguarde...
                        </>
                    ),
                    type: "info",
                    closeOnClickOutside: false,
                })
            )

            let qrCodePinPad = {
                timeout: 150,
                layout: "0",
                title: "",
                content: paymentCreated?.pix_qr_code,
            }

            try {
                await showPixQrCode(qrCodePinPad) //Enviar qrcode para o pinpad
            } catch (e) {
                pinPadOk = false
                console.log(e)
            }

            var sweetAlertConfig = {
                title: "",
                text: (
                    <>
                        <img src={logoPix} alt="" height="40" />
                        <div className="mt-3 mb-3">
                            <strong>
                                Valor a Pagar:{" "}
                                {new Intl.NumberFormat("pt-br", {
                                    style: "decimal",
                                    currency: "BRL",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }).format(parseInt(paymentCreated.value) / 100)}
                            </strong>
                        </div>
                        <div className="mb-2">
                            {paymentCreated?.pix_qr_code ? (
                                <QRCode value={paymentCreated?.pix_qr_code} />
                            ) : null}
                        </div>
                        <div className="">
                            <Row className="justify-content-center">
                                <Col sm="6">
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={() => {
                                            const copyToClipboard = str => {
                                                const el = document.createElement(
                                                    "textarea"
                                                )
                                                el.value = str
                                                el.setAttribute("readonly", "")
                                                el.style.position = "absolute"
                                                el.style.left = "-9999px"
                                                document.body.appendChild(el)
                                                el.select()
                                                document.execCommand("copy")
                                                document.body.removeChild(el)
                                            }
                                            copyToClipboard(
                                                paymentCreated.pix_qr_code
                                            )
                                            dispatch(
                                                showToast({
                                                    title: "",
                                                    text:
                                                        "Código do Pix Copiado!",
                                                    type: "success",
                                                })
                                            )
                                        }}
                                    >
                                        Copiar código PIX
                                    </button>
                                </Col>
                            </Row>
                        </div>
                        <div className="mt-4 mb-2">
                            <strong>Aguardando Pagamento...</strong>
                            <div
                                className="spinner-border text-primary"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>

                        <div className="mb-4">
                            <strong>
                                Solicite a leitura do QRCode na tela do
                                Computador ou do PinPad.
                            </strong>
                        </div>
                        {!pinPadOk ? (
                            <div className="mb-4" style={{ color: "red" }}>
                                <small>
                                    Não foi possível conectar ao PinPad.
                                    Verifique se está plugado no computador e o
                                    VittáTEF está aberto.
                                </small>
                            </div>
                        ) : null}

                        <div className="">
                            <Row>
                                <Col sm="6" className="mb-3">
                                    <Button
                                        className="form-control btn btn-danger waves-effect waves-light"
                                        onClick={() => {
                                            closePixMonitor()
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                </Col>
                                <Col sm="6">
                                    <Button
                                        className="form-control btn btn-info waves-effect waves-light"
                                        onClick={async () => {
                                            try {
                                                await showPixQrCode(
                                                    qrCodePinPad
                                                ) //Enviar qrcode para o pinpad //todo - deixar o botao desabilitado por x segundos
                                            } catch (e) {
                                                console.log(e)
                                            }
                                        }}
                                    >
                                        Atualizar PinPad
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </>
                ),
                type: "",
                closeOnClickOutside: false,
            }

            dispatch(showSweetAlertDialog(sweetAlertConfig))

            intervalCheckTransaction = setInterval(async () => {
                try {
                    // console.log("call check transaction");

                    transactionChecks++

                    let closeMonitor = false
                    let sweetAlertConfig = {}

                    let data = await checkTransactionUpdates(paymentCreated.id)

                    //console.log("data check", data);

                    if (SALES_ORDERS_PAYMENT_STATUS.PAID == data.status) {
                        closeMonitor = true
                        sweetAlertConfig = {
                            title: "Sucesso!",
                            text: <>Pagamento Realizado com sucesso!</>,
                            type: "success",
                            showConfirm: true,
                        }
                    } else if (
                        SALES_ORDERS_PAYMENT_STATUS.SUSPENDED == data.status
                    ) {
                        closeMonitor = true
                        sweetAlertConfig = {
                            title: "Pagamento Suspenso",
                            text: <>O pagamento foi suspenso.</>,
                            type: "error",
                            showConfirm: true,
                            closeOnClickOutside: false,
                        }
                    } else if (
                        SALES_ORDERS_PAYMENT_STATUS.CANCELED == data.status
                    ) {
                        closeMonitor = true
                        sweetAlertConfig = {
                            title: "Pagamento Cancelado",
                            text: <>O pagamento foi cancelado.</>,
                            type: "error",
                            showConfirm: true,
                            closeOnClickOutside: false,
                        }
                    } else {
                        if (transactionChecks >= 60) {
                            closeMonitor = true
                            sweetAlertConfig = {
                                title: "Pagamento Expirado",
                                text: (
                                    <>
                                        O tempo limíte para pagamento foi
                                        expirado.
                                    </>
                                ),
                                type: "error",
                                showConfirm: true,
                                closeOnClickOutside: false,
                            }
                        }
                    }

                    if (closeMonitor) {
                        closePixMonitor(true)

                        dispatch(showSweetAlertDialog(sweetAlertConfig))
                    }
                } catch (e) {
                    console.log(e)
                }
            }, transactionCheckTimeInterval)
        })
    }

    const confirmCancelPayment = async (
        item,
        cancelReason,
        vittapag_verify = false
    ) => {
        try {
            let text = "Recebimento Cancelado no VittáNet!"

           let payment = await cancelPaymentSalesOrderPaymentService(item.id, cancelReason)
       
            let auxPageControl = { ...pageControl }
            auxPageControl.operations.cancel = true
            auxPageControl.lastForceLoadData = new Date().getTime()
            auxPageControl.clearPageOnLoadData = false

            setPageControl(auxPageControl)

            if (vittapag_verify) {
                return payment?.payment
            } else {
                dispatch(
                    showToast({
                        title: "Sucesso",
                        text: text,
                        type: "success",
                    })
                )

                dispatch(
                    showSweetAlertDialog({
                        title: "Cancelamento do Pagamento Realizado!",
                        text: <>Sucesso!</>,
                        type: "success",
                        showConfirm: true,
                    })
                )
            }
        } catch (e) {
            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "sales_order_cant_cancel_payment_electronic":
                        textError =
                            "O pagamento eletrônico não pode ser cancelado. Algum recebedor pode não ter saldo na conta para realizar o estorno."
                        break
                    case "sales_order_cant_cancel_payment":
                        textError =
                            "O pagamento não pode ser cancelado, verifique o status do pedido."
                        break
                    case "sales_order_payment_not_found":
                        textError = "Pagamento não encontrado."
                        break
                    case "prof_transfer_invoice_created":
                        textError =
                            "Existe uma nota de repasse médico ativa. Cancele a nota para cancelar o pagamento."
                        break
                    case "os_not_opened":
                        textError =
                            "Ops.. Não existe OS ativa para ser cancelada. Procure o Suporte técnico."
                        break
                    case "sales_order_cant_cancel_payment_prof_transf_invoice":
                        textError =
                            "Existe uma nota de repasse médico ativa. Cancele a nota para cancelar o pagamento."
                        break
                    case "sales_order_cant_cancel_payment_exams_orders":
                        textError =
                            "Existem pedidos de exames ativos. Cancele-os para cancelar o pagamento."
                        break
                    case "sales_order_cant_cancel_payment_exec":
                        textError =
                            "Há execuções associadas ao pagamento. Para cancelar o pagamento, é necessário cancelar essas execuções primeiro."
                        break
                    case "exists_items_executed":
                        textError =
                            "Há execuções associadas ao pagamento. Para cancelar o pagamento, é necessário cancelar essas execuções primeiro."
                        break
                    case "insufficient_cash_balance":
                        textError = 
                            "Saldo insuficiente para realizar o pagamento."
                        break
                    case "cash_control_not_open":
                        textError = 
                            "Necessário abrir o caixa para cancelar o pagamento."
                        break
                    case "insufficient_general_cash_balance":
                        textError = "Saldo insuficiente no caixa geral para o o cancelamento do pagamento.";
                        break
                }
            }

            return dispatch(
                showSweetAlertDialog({
                    title: "Erro",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                    closeOnClickOutside: false,
                })
            )
        }
    }

    let createPayment = async saveData => {
        let paymentCreated = null
        try {
            paymentCreated = await createSalesOrderPaymentService(saveData)

            dispatch(
                showToast({
                    title: "Sucesso",
                    text: "Registro salvo com Sucesso!",
                    type: "success",
                })
            )

            if (autoPrintReceipt && paymentCreated?.payment_created?.status == SALES_ORDERS_PAYMENT_STATUS.PAID) {
                showPaymentReceipt(paymentCreated, pageData.order_data)
            }
        } catch (e) {
            console.log(e)
            setPageControl({ ...pageControl, ...{ submiting: false } })

            let textError =
                "Houve um erro ao processar sua solicitação, tente novamente mais tarde..."

            if (e?.response?.status == 403) {
                textError =
                    "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
            } else if (e?.response?.data) {
                switch (e.response.data.error_code) {
                    case "payment_greater_than_order":
                        textError =
                            "O Valor do pagamento não pode ser maior que o valor do pedido de venda."
                        break
                    case "unauthorized_manual_discount":
                        textError =
                            "O pagamento deve ser em dinheiro pois há descontos manuais aplicados na venda."
                        break
                    case "item_value_cannot_be_less_than_or_equal_to_0":
                        textError =
                            "O valor do serviço de odontologia não pode ser menor ou igual a zero"
                        break
                    case "item_value_cannot_be_greater_than_total_value":
                        textError =
                            "O valor do serviço de odontologia não pode ser maior que o valor total"
                        break
                    case "item_value_cannot_be_greater_than_total_value_minus_what_has_already_been_paid":
                        textError =
                            "O valor do serviço de odontologia não pode ser maior que o valor de referência"
                        break
                    case "item_already_paid":
                        textError = "O serviço de odontologia já foi pago."
                        break
                    case "unauthorized_manual_mode_pix":
                        textError =
                            "Não é possível realizar pix no modo manual."
                        break
                        case "exists_payments_pending":
                            textError =
                                "Existem pagamentos pendentes, para criar um novo, cancele os que estão aguardando pagamento."
                            break
                    case "benefit_has_no_balance":
                        return showButtonOptions()
                    // textError = "Benefício não possui saldo!"
                    case "cant_apply_vtplus":
                        return showButtonOptions()
                    case "cash_control_not_open":
                        textError = "Necessário abrir o caixa para receber o pagamento."
                        break
                }
            }

            return dispatch(
                showSweetAlertDialog({
                    title: "Ops... ",
                    text: textError,
                    type: "error",
                    showConfirm: true,
                })
            )
        }

        return paymentCreated
    }

    let handleSubmitPaymentError = e => {
        console.log("handleSubmitPaymentError", e)

        let textError = ""

        if (e.custom_error) {
            if (e.custom_error.code == "can_not_persist_transaction_paid") {
                textError =
                    "Não foi possível registrar o pagamento no vittanet apesar da transação ter sido aprovada. Realize o cancelamento deste pagamento e tente novamente."
            }
        } else if (e?.response?.status == 403) {
            textError =
                "Você não possui permissões para realizar essa operação. Solicite ao seu Gestor."
        } else if (e?.response?.data) {
            switch (e.response.data.error_code) {
                case "sales_order_payment_update_block_by_status":
                    textError =
                        "O status deste pagamento não pode mais ser alterado!"
                    break
                case "sales_order_payment_invalid_status":
                    textError = "Novo status para o pagamento é inválido."
                    break
                case "payment_greater_than_order":
                    textError =
                        "O Valor do pagamento não pode ser maior que o valor do pedido de venda."
                    break
                case "invalid_payment_form":
                    textError =
                        "A forma de pagamento selecionada não está disponível."
                    break
                case "sales_order_not_waiting_payment":
                    textError = "O Pedido não está aguardando pagamento."
                    break
                case "professional_electronic_payment_disabled":
                    if (
                        e.response.data.error_data?.professional &&
                        e.response.data.error_data?.professional?.name
                    ) {
                        textError =
                            "O Profissional " +
                            e.response.data.error_data.professional.name +
                            " que participa do split de recebimento desta venda não está habilitado para receber de forma eletrônica."
                    } else {
                        textError =
                            "Um profissional que participa do split de recebimento desta venda não está habilitado para receber de forma eletrônica."
                    }
                    break
                case "unit_electronic_payment_disabled":
                    if (
                        e.response.data.error_data?.unit &&
                        e.response.data.error_data?.unit?.name
                    ) {
                        textError =
                            "A unidade " +
                            e.response.data.error_data.unit.name +
                            " que participa do split de recebimento desta venda não está habilitada para receber de forma eletrônica."
                    } else {
                        textError =
                            "Uma unidade que participa do split de recebimento desta venda não está habilitada para receber de forma eletrônica."
                    }
                    break
                case "professional_electronic_payment_unavailable_quant_prof_charge":
                    textError =
                        "Esse pedido não pode ser pago de forma eletrônica devido limitações. Realize o recebimento de forma manual."
                    break
                case "unauthorized_manual_discount":
                    textError =
                        "O pagamento deve ser em dinheiro pois há descontos manuais aplicados na venda."
                    break
                case "item_value_cannot_be_less_than_or_equal_to_0":
                    textError =
                        "O valor do serviço de odontologia não pode ser menor ou igual a zero"
                    break
                case "item_value_cannot_be_greater_than_total_value":
                    textError =
                        "O valor do serviço de odontologia não pode ser maior que o valor total"
                    break
                case "item_value_cannot_be_greater_than_total_value_minus_what_has_already_been_paid":
                    textError =
                        "O valor do serviço de odontologia não pode ser maior que o valor de referência"
                    break
                case "item_already_paid":
                    textError = "O serviço de odontologia já foi pago."
                    break
                case "unauthorized_manual_mode_pix":
                    textError = "Não é possível realizar pix no modo manual."
                    break
                case "benefit_has_no_balance":
                    return showButtonOptions()
                // textError = "Benefício não possui saldo!"
                case "cant_apply_vtplus":
                    return showButtonOptions()
                case "cash_control_not_open":
                    textError = "Necessário abrir o caixa para receber o pagamento."
                    break
            }
        }

        if (textError.length == 0)
            textError =
                "Transação não realizada. Houve um erro ao processar sua solicitação."

        setPaymentActiveTab("clean")

        return dispatch(
            showSweetAlertDialog({
                title: "Ops... ",
                text: textError,
                type: "error",
                showConfirm: true,
            })
        )
    }
    const handleElectronicFlow = async data => {
        if (
            pageData?.order_data?.unit?.electronic_vittapag_payment_status == 1
        ) {
            let result = await createPayment(data)

            if (!result?.payment_created) return
            const referenceId = result?.payment_created?.vittapag_id
            const action = "create_transaction"
            const sales_order_payment_id = result?.payment_created?.id
            startFluxPayment(referenceId, action, sales_order_payment_id)
        } else {
            return handleElectronicPixFlow(data)
        }
    }
    // let handleElectronicFlow = async data => {
    //     if (data.pay_form_id == "PIX") {
    //         await handleElectronicPixFlow(data)
    //     }
    //     else
    //     {
    //         //comentado para fazer o embed
    //         // let response = await createPayment(data);
    //         // //alterar pelo id do vittapag...
    //          //const referenceId = response?.payment_created?.vittapag_id;

    //         // if(!referenceId) return;
    //         const referenceId = 5;
    //         if(data.pay_form_id == "CD")
    //         {
    //             await handleElectronicCDFlow(data,referenceId)
    //         }
    //         if(data.pay_form_id == "CC")
    //         {
    //             await handleElectronicCCFlow(data,referenceId)
    //         }
    //     }
    // }
    const handleElectronicCDFlow = async (data, referenceId) => {
        let dataToCreatePayment = {
            type: "payment",
            paymentType: ZOOP_SDK_PAYMENT_TYPES.DEBIT,
            amount: data.value,
            installments: 1,
            referenceId: referenceId,
        }

        //setControlModalCardPayments({show:true,data:dataToCreatePayment,data_form:data,action:"make_payment"})
        startFluxPayment(dataToCreatePayment, "create_transaction")
    }

    const handleElectronicCCFlow = async (data, referenceId) => {
        let dataToCreatePayment = {
            type: "payment",
            paymentType: ZOOP_SDK_PAYMENT_TYPES.CREDIT,
            amount: data.value,
            installments: data.installments,
            referenceId: referenceId,
        }
        if (data.installments > 1) {
            dataToCreatePayment.installments =
                ZOOP_SDK_PAYMENT_TYPES.INSTALLMENT_CREDIT
        }

        //setControlModalCardPayments({show:true,data:dataToCreatePayment,data_form:data,action:"make_payment"})
        startFluxPayment(dataToCreatePayment, "create_transaction")
    }

    const startFluxPayment = (referenceId, action, sales_order_payment_id) => {
        const transactionId = referenceId
        // const url = `http://127.0.0.1:8080/external-script.js?${new Date().getTime()}`
        const url = `${
            process.env.REACT_APP_VITTA_PAG_SERVER
        }/checkout/public/external-script.js?${new Date().getTime()}`
        const parameters = {
            transactionId: transactionId,
            action: action,
        }

        let callback = {
            success: function (successData) {
                if (action == "cancel_transaction") {
                    cancelPayment({ id: sales_order_payment_id })
                } else {
                    checkTransactionsAfterCheckout(sales_order_payment_id)
                }
            },
            abort: function (errorData) {
                console.log(
                    "transação falhou....",
                    errorData,
                    referenceId,
                    sales_order_payment_id
                )
                checkTransactionsAfterCheckout(sales_order_payment_id)
            },
        }

        function initializeExternalScript() {
            if (typeof vittaPagBox === "function") {
                vittaPagBox(parameters, callback)
            }
        }

        if (typeof vittaPagBox == "undefined") {
            const script = document.createElement("script")

            script.src = url
            script.async = true

            script.onload = initializeExternalScript

            document.body.appendChild(script)
        } else {
            initializeExternalScript()
        }
    }

    const checkTransactionsAfterCheckout = async sales_order_id => {
        let payment = null

        setControlCheckPaymentLoadingModal({
            show: true,
        })

        try {
            payment = await checkTransactionUpdates(sales_order_id)
        } catch (e) {
            console.log(e)
        }

        let auxPageControl = { ...pageControl }
        auxPageControl.lastForceLoadData = new Date().getTime()
        auxPageControl.clearPageOnLoadData = false
        setPageControl(auxPageControl)
        setControlCheckPaymentLoadingModal({
            show: false,
        })
        return payment
    }

    let handleElectronicPixFlow = async data => {
        dispatch(
            showSweetAlertDialog({
                title: "Criando Transação...",
                text: (
                    <>
                        <div
                            className="spinner-border text-primary"
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div>{" "}
                        Aguarde...
                    </>
                ),
                type: "info",
                closeOnClickOutside: false,
            })
        )

        let payment = await createSalesOrderPaymentService(data)

        if (!payment) throw new Error("Create Payment Error")

        await showPixPaymentMonitor(payment?.payment_created)
    }

    const onSubmit = async (data_form, actions) => {
        let transaction_mode_electronic =
            pageControl.useTef &&
            PAYMENT_FORMS_ELECTRONIC.includes(paymentActiveTab)
                ? true
                : false

        if(!pageControl?.electronic_vittapag_payment_status && !["PIX"].includes(paymentActiveTab))
        {
            transaction_mode_electronic = false;
        }

        //Validações de dados
        if (
            !["money", "CC", "CD", "PIX", "financial"].includes(
                paymentActiveTab
            )
        )
            return dispatch(
                showSweetAlertDialog({
                    title: "Atenção",
                    text:
                        "Forma de Pagamento selecionada não foi identificada pelo sistema.",
                    type: "error",
                    showConfirm: true,
                })
            )

        if (paymentActiveTab == "money") {
            if (pageData.form_data.total_order > 0) {
                if (data_form.money_value <= 0) {
                    return dispatch(
                        showSweetAlertDialog({
                            title: "Atenção",
                            text: "Informe o valor do pagamento em Dinheiro.",
                            type: "error",
                            showConfirm: true,
                        })
                    )
                }
            } else if (data_form.money_value < 0) {
                //Pagamento em dinheiro pode ser zerado por enquanto. Ex: Cortesia
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção",
                        text: "Insira os valores de pagamento Corretamente.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
        } else if (paymentActiveTab == "CC") {
            if (data_form.cc_value <= 0) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção",
                        text:
                            "Informe o valor do pagamento por cartão de crédito.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }

            if (!data_form.cc_card_flag && !transaction_mode_electronic) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção",
                        text:
                            "Para pagamento Manual, Informe a Bandeira do cartão de crédito.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }

            if (!data_form.cc_nsu_code && !transaction_mode_electronic) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção",
                        text: "Para pagamento Manual, Informe o código NSU/DOC",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
        } else if (paymentActiveTab == "CD") {
            if (data_form.cd_value <= 0) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção",
                        text:
                            "Informe o valor do pagamento por cartão de débito.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }

            if (!data_form.cd_card_flag && !transaction_mode_electronic) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção",
                        text:
                            "Para pagamento Manual, Informe a Bandeira do cartão de débito.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }

            if (!data_form.cd_nsu_code && !transaction_mode_electronic) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção",
                        text: "Para pagamento Manual, Insira o código NSU/DOC",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
        } else if (paymentActiveTab == "PIX") {
            if (pageData.form_data.total_order > 0) {
                if (data_form.pix_value <= 0) {
                    return dispatch(
                        showSweetAlertDialog({
                            title: "Atenção",
                            text: "Informe o valor do pagamento em PIX.",
                            type: "error",
                            showConfirm: true,
                        })
                    )
                }
            }
        } else if (paymentActiveTab == "financial") {
            if (data_form.financial_value <= 0) {
                return dispatch(
                    showSweetAlertDialog({
                        title: "Atenção",
                        text: "Insira os valores de pagamento Corretamente.",
                        type: "error",
                        showConfirm: true,
                    })
                )
            }
        }

        if (pageControl.submiting) return

        setPageControl({ ...pageControl, ...{ submiting: true } })

        let saveData = {
            sales_order_id: data.id,
            payer_name: data_form.payer_name
                ? data_form.payer_name
                : data?.patient.name,
            payer_cpf: data_form.payer_cpf
                ? data_form.payer_cpf
                : data?.patient?.cpf,
            transaction_mode: transaction_mode_electronic
                ? TRANSACTION_MODES.ELECTRONIC
                : TRANSACTION_MODES.MANUAL, //1 = MANUAL, 2 = ELECTRONIC
            financial_id: data_form?.financial?.value,
        }

        if (paymentActiveTab == "money") {
            saveData.pay_form_id = "R$"
            saveData.value = parseInt(
                data_form.money_value.toFixed(2).replace(/[^0-9]/g, "")
            )
            saveData.installments = 1
            saveData.transaction_mode = TRANSACTION_MODES.MANUAL //forçar manual
        } else if (paymentActiveTab == "PIX") {
            saveData.pay_form_id = "PIX"
            saveData.value = parseInt(
                data_form.pix_value.toFixed(2).replace(/[^0-9]/g, "")
            )
            saveData.installments = 1
        } else if (paymentActiveTab == "CC") {
            saveData.pay_form_id = "CC"
            saveData.value = parseInt(
                data_form.cc_value.toFixed(2).replace(/[^0-9]/g, "")
            )
            saveData.installments = data_form.cc_parcels.value
            saveData.card_nsu = transaction_mode_electronic
                ? null
                : data_form.cc_nsu_code
            saveData.card_flag = transaction_mode_electronic
                ? null
                : data_form.cc_card_flag?.value
        } else if (paymentActiveTab == "CD") {
            saveData.pay_form_id = "CD"
            saveData.value = parseInt(
                data_form.cd_value.toFixed(2).replace(/[^0-9]/g, "")
            )
            saveData.installments = 1
            saveData.card_nsu = transaction_mode_electronic
                ? null
                : data_form.cd_nsu_code
            saveData.card_flag = transaction_mode_electronic
                ? null
                : data_form.cd_card_flag?.value
        } else if (paymentActiveTab == "financial") {
            saveData.pay_form_id = "FIN"
            saveData.value = parseInt(
                data_form.financial_value.toFixed(2).replace(/[^0-9]/g, "")
            )
            saveData.installments = 1
            saveData.transaction_mode = TRANSACTION_MODES.MANUAL
        }
        if (
            subscriptionData &&
            subscriptionData?.subscription?.subscription_id
        ) {
            saveData.subscription_id =
                subscriptionData.subscription.subscription_id
        }
        if (data?.service_type) {
            saveData.service_type = data.service_type
        }
        if (controlOdontologicData?.selectedData?.length > 0) {
            saveData.odontologic_data = controlOdontologicData.selectedData
        }

        //Cortesia
        // todo - ao salvar pedido com forma de pagamento cortesia, alterar status do pedido para pago.
        setShowLoadingButtonPay(true)
        try {
            //Pagar com TEF
            if (transaction_mode_electronic) {
                await handleElectronicFlow(saveData)
            } else {
                await createPayment(saveData)
            }

            let auxPageControl = { ...pageControl }
            auxPageControl.operations.create = true
            auxPageControl.lastForceLoadData = new Date().getTime()
            auxPageControl.clearPageOnLoadData = false
            auxPageControl.submiting = false

            setPaymentActiveTab("clean")

            setPageControl(auxPageControl)
        } catch (e) {
            setPageControl({ ...pageControl, ...{ submiting: false } })
            handleSubmitPaymentError(e)
            //Sentry.captureException(e);
        }
        setShowLoadingButtonPay(false)
    }

    const renderStatusBadge = status => {
        let text = ""
        let badgeClass = "badge-sm badge-soft-success"
        switch (status) {
            case SALES_ORDERS_PAYMENT_STATUS.PENDING:
                text = "Pendente"
                badgeClass = "badge-soft-warning"
                break
            case SALES_ORDERS_PAYMENT_STATUS.PAID:
                text = "Pago"
                badgeClass = "badge-soft-success"
                break
            case SALES_ORDERS_PAYMENT_STATUS.CANCELED:
                text = "Cancelado"
                badgeClass = "badge-soft-danger"
                break
            case SALES_ORDERS_PAYMENT_STATUS.SUSPENDED:
                text = "Suspenso"
                badgeClass = "badge-soft-danger"
                break
        }

        return (
            <Badge className={"font-size-11 " + badgeClass} pill>
                {text}
            </Badge>
        )
    }

    const calcTotalNota = items => {
        let total = 0
        for (let i = 0; i < items.length; i++) {
            let useItem = items[i]
            total += useItem.quantity * (useItem.price ? useItem.price : 0)
        }

        return total
    }

    const showPaymentReceipt = (payment, sales_order, onClose) => {
        let html = "<html> <head>"
        html +=
            '<meta content="text/html; charset=UTF-8" http-equiv="Content-Type">'
        html += `<style>
         @media print {
        .not_print{
         display: none;
              }
         }

         body {
            font-family: Verdana;
            font-size: 12px !important;
         }
         table {
            font-family: Verdana;
            font-size: 12px !important;
         }

        .tr_items {
            background-color: #f2f2f2;
            background-image: none;
            opacity: 1;
        }

       </style> </head> `
        html += "<body>"

        html += `<div style="text-align: center;"><h2>Recibo de Pagamento</h2><div/>`
        html += " <br/>"
        html += `<table width="100%">
            <tr><td width="40%"><strong>Paciente:</strong> ${
                sales_order.patient.name
            } </td><td><strong>CPF:</strong> ${
            sales_order.patient.cpf
        } </td><tr>
            <tr><td><strong>Data de Nascimento: </strong> ${
                sales_order.patient.birth_date
                    ? new Date(
                          sales_order.patient.birth_date + " 00:00:00"
                      ).toLocaleDateString("pt-BR")
                    : null
            }</td> </tr>
            <tr><td><strong>Código Recebimento:</strong> ${
                payment.id
            }</td><td><strong>Data Recebimento:</strong> ${
            payment.created_at
                ? new Date(payment.created_at).toLocaleDateString("pt-BR", {
                      hour: "2-digit",
                      hour12: false,
                      minute: "2-digit",
                      second: "2-digit",
                  })
                : null
        }</td><td></td<tr>
            <tr><td><strong>Forma de Pagamento:</strong> ${
                payment.pay_form_id == "R$"
                    ? "Dinheiro"
                    : payment.pay_form_id == "CC"
                    ? "Cartão de Crédito " +
                      (payment.card_flag ? payment.card_flag : "")
                    : payment.pay_form_id == "CD"
                    ? "Cartão de Débito " +
                      (payment.card_flag ? payment.card_flag : "")
                    : payment.pay_form_id == "CRT"
                    ? "Cortesia"
                    : payment.pay_form_id == "PIX"
                    ? "PIX"
                    : payment.pay_form_id == "FIN"
                    ? "Financeira"
                    : ""
            } </td><td><strong>Parcelas:</strong>  ${
            payment.installments
        } </td<tr>
            <tr><td><strong>Nome do Pagador: </strong> ${
                payment.payer_name
            } <td><strong>CPF do pagador: </strong> ${
            payment.payer_cpf
        }</td></td> </tr>  `

        if (payment.card_nsu) {
            html += `<tr><td><strong>DOC/NSU: </strong> ${payment.card_nsu}</td> </tr>  `
        }

        html += `     
            <tr>
            <td><strong>Pedido de Venda:</strong> ${payment.sales_order_id}</td>
            <td><strong>Total Pedido:</strong> R$ ${new Intl.NumberFormat(
                "pt-br",
                {
                    style: "decimal",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }
            ).format(calcTotalNota(sales_order.items) / 100)}</td>
            <td><strong>Valor Pago:</strong> R$ ${new Intl.NumberFormat(
                "pt-br",
                {
                    style: "decimal",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }
            ).format(payment.value / 100)}</td>
            <tr>
        </table> `

        html += " <br/>"
        html += `<div style="text-align: center;"><h3>Itens da Venda</h3><div/>`
        html += `<table width="100%" className="items">`
        html += `<thead>`
        html += ` <tr className="tr_items"> <td><strong>Item</strong></td> <td><strong>Quantidade</strong></td> <td><strong>Preço Tab. Unit.</strong></td> <td><strong>Tot. Desconto</strong></td> <td><strong>Tot. Acréscimo</strong></td><td><strong>Valor Total</strong></td></tr> `
        html += `</thead>`
        for (let i = 0; i < sales_order.items.length; i++) {
            let useItem = sales_order.items[i]

            html += `<tr> 
                <td>${useItem.item.name}</td> 
                <td>${useItem.quantity}</td> 
                <td>${
                    "R$ " +
                    new Intl.NumberFormat("pt-br", {
                        style: "decimal",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(useItem.price_table / 100)
                }</td> 
                <td>${
                    "R$ " +
                    new Intl.NumberFormat("pt-br", {
                        style: "decimal",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(useItem.discount / 100)
                }</td> 
                <td>${
                    "R$ " +
                    new Intl.NumberFormat("pt-br", {
                        style: "decimal",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(useItem.addition / 100)
                }</td> 
                <td>${
                    "R$ " +
                    new Intl.NumberFormat("pt-br", {
                        style: "decimal",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }).format(
                        (useItem.quantity *
                            (useItem.price ? useItem.price : 0)) /
                            100
                    )
                }</td>
            </tr>  `
        }
        html += `</table> <hr>`

        html += `<div style="text-align: left">`
        html += " <br/>"
        html += `${sales_order.unit.social_name} - CNPJ: ${sales_order.unit.cnpj}`
        html += " <br/>"
        html += `Contato: ${sales_order.unit.phone}`
        html += " <br/>"
        html += `Endereço: ${sales_order.unit.address}, ${sales_order.unit.district}`
        html += " <br/>"
        html += `Cidade: ${sales_order.unit.city} - ${sales_order.unit.state}`
        html += `</div>`

        html += "<script> window.print() </script>"

        html += "</body> "
        html += "</html>"
        let w = 1024
        let h = 768
        const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
        const x = window.top.outerWidth / 2 + window.top.screenX - w / 2

        const winUrl = URL.createObjectURL(
            new Blob([html], { type: "text/html" })
        )

        window.open(
            winUrl,
            "Recibo de Pagamento " + payment.id,
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, 
            resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
        )
    }

    const renderLoading = () => {
        return (
            <Row align="center" className="mt-5 mb-4">
                <Col sm="12">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </Col>
            </Row>
        )
    }
    const showButtonOptions = () => {
        dispatch(
            showSweetAlertDialog({
                title: "Atenção!",
                text:
                    "Não foi possível utilizar os descontos vittá + nesse pedido, deseja transformar esses descontos em descontos manuais?",
                type: "warning",
                onConfirmAction: () => {
                    handleChangeDiscountsType()
                },
                showCancel: true,
                showConfirm: true,
                cancelBtnText: "Não",
                confirmBtnText: "Sim",
            })
        )
    }
    const handleChangeDiscountsType = async () => {
        try {
            await changeTypeDiscountsApplied(data?.id)
            dispatch(
                showToast({
                    title: "Sucesso!",
                    text: "Uma solicitação de aprovação foi gerada!",
                    type: "success",
                })
            )
            closeModal({ cancel: true, create: true })
        } catch (e) {
            console.log(e)
        }
    }

    if (!show) return null
    const renderForm = (values, docData, setFieldValue) => {
        return (
            <>
                <Row>
                    <Col sm="6">
                        <Label>Paciente</Label>
                        <Input
                            type="text"
                            disabled
                            className="bg-light"
                            value={values.patient_name}
                        />
                    </Col>
                    <Col sm="6">
                        <Label>Pedido</Label>
                        <Input
                            type="text"
                            disabled
                            className="bg-light"
                            value={values.order_id}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm="6" className="mt-2">
                        <Label>Total Pedido</Label>
                        <FastField
                            name={`total_order`}
                            defaultValue={values.total_order}
                            displayType={"input"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowEmptyFormatting={true}
                            prefix={"R$ "}
                            disabled
                            className="form-control bg-light"
                            component={CurrencyInput}
                        />
                    </Col>
                    <Col sm="6" className="mt-2">
                        <Label>Valor Pago</Label>
                        <NumberFormat
                            value={pageData.form_data.total_paid}
                            displayType={"input"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowEmptyFormatting={true}
                            prefix={"R$ "}
                            disabled
                            className="form-control bg-light"
                        />
                    </Col>
                </Row>

                {pageData.payments.length > 0 && paymentActiveTab == "clean" ? (
                    <>
                        <hr></hr>
                        <h5 className="modal-title mt-4 mb-2" id="myModalLabel">
                            Pagamentos Realizados
                        </h5>
                        <div
                            className="table-responsive"
                            style={{ maxHeight: "140px" }}
                        >
                            <Table
                                className="table mb-0"
                                style={{ fontSize: "12px" }}
                            >
                                <thead className="thead-light">
                                    <tr>
                                        <th>Data</th>
                                        <th>Forma de Pag.</th>
                                        <th>Valor</th>
                                        <th>Parcelas</th>
                                        <th>Status</th>
                                        <th>Ações</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {pageData.payments.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                {item.created_at
                                                    ? new Date(
                                                          item.created_at
                                                      ).toLocaleDateString(
                                                          "pt-BR",
                                                          {
                                                              hour: "2-digit",
                                                              hour12: false,
                                                              minute: "2-digit",
                                                              second: "2-digit",
                                                          }
                                                      )
                                                    : null}
                                            </td>
                                            <td>
                                                {item.pay_form_id == "R$"
                                                    ? "Dinheiro"
                                                    : item.pay_form_id == "CC"
                                                    ? "Cartão de Crédito " +
                                                      (item.card_flag
                                                          ? item.card_flag
                                                          : "")
                                                    : item.pay_form_id == "CD"
                                                    ? "Cartão de Débito " +
                                                      (item.card_flag
                                                          ? item.card_flag
                                                          : "")
                                                    : item.pay_form_id == "PIX"
                                                    ? "PIX"
                                                    : item.pay_form_id == "CRT"
                                                    ? "Cortesia"
                                                    : item.pay_form_id == "FIN"
                                                    ? "Financeira"
                                                    : ""}{" "}
                                                {item?.transaction_mode ==
                                                TRANSACTION_MODES.ELECTRONIC ? (
                                                    <small>(eletrônico)</small>
                                                ) : item.pay_form_id != "R$" ? (
                                                    <small>(manual)</small>
                                                ) : null}
                                            </td>
                                            <td>
                                                {(item.value > 0
                                                    ? item.value / 100
                                                    : 0
                                                ).toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: "BRL",
                                                })}
                                            </td>
                                            <td>
                                                {item.installments == 1
                                                    ? "À Vista"
                                                    : item.installments}
                                            </td>
                                            <td>
                                                {renderStatusBadge(item.status)}
                                            </td>
                                            {/* <td>{item.card_nsu}</td> */}
                                            <td
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "12px",
                                                    alignItems: "center",
                                                    justifyItems: "center",
                                                }}
                                            >
                                                {item.status ==
                                                    SALES_ORDERS_PAYMENT_STATUS.PENDING &&
                                                item.transaction_mode ==
                                                    TRANSACTION_MODES.ELECTRONIC &&
                                                ((item?.pix_qr_code &&
                                                    item.status ==
                                                        SALES_ORDERS_PAYMENT_STATUS.PENDING) ||
                                                    pageData?.order_data?.unit
                                                        ?.electronic_vittapag_payment_status) ? (
                                                    <div>
                                                        <i
                                                            className="fas fa-sync-alt fa-lg"
                                                            style={{
                                                                color:
                                                                    "rgb(41, 149, 211)",
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                            id={
                                                                "eyetooltip" +
                                                                item.id
                                                            }
                                                            onClick={async () => {
                                                                if (
                                                                    pageData
                                                                        ?.order_data
                                                                        ?.unit
                                                                        ?.electronic_vittapag_payment_status
                                                                ) {
                                                                    const action =
                                                                        "create_transaction"
                                                                    const referenceId =
                                                                        item?.vittapag_id
                                                                    const sales_order_payment_id =
                                                                        item?.id
                                                                    startFluxPayment(
                                                                        referenceId,
                                                                        action,
                                                                        sales_order_payment_id
                                                                    )
                                                                } else {
                                                                    try {
                                                                        await showPixPaymentMonitor(
                                                                            item
                                                                        )
                                                                    } catch (e) {
                                                                        console.log(
                                                                            e
                                                                        )
                                                                        //sentry
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target={
                                                                    "eyetooltip" +
                                                                    item.id
                                                                }
                                                                style={{
                                                                    backgroundColor:
                                                                        "rgb(41, 149, 211)",
                                                                    color:
                                                                        "white",
                                                                }}
                                                            >
                                                                Abrir Vittá
                                                                Pagamentos
                                                            </UncontrolledTooltip>
                                                        </i>
                                                    </div>
                                                ) : null}
                                                {item.status ==
                                                SALES_ORDERS_PAYMENT_STATUS.PAID ? (
                                                    <div>
                                                        {" "}
                                                        <Link
                                                            to="#"
                                                            className="text-primary "
                                                            onClick={() => {
                                                                showPaymentReceipt(
                                                                    item,
                                                                    pageData.order_data,
                                                                    () => {
                                                                        console.log(
                                                                            "closedddd"
                                                                        )
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <i
                                                                class="fas fa-print font-size-22"
                                                                id={
                                                                    "printtooltip" +
                                                                    item.id
                                                                }
                                                                style={{
                                                                    position:
                                                                        "relative",
                                                                    top: "3px",
                                                                }}
                                                            ></i>
                                                            <UncontrolledTooltip
                                                                placement="left"
                                                                target={
                                                                    "printtooltip" +
                                                                    item.id
                                                                }
                                                            >
                                                                Imprimir Recibo
                                                                de Pagamento
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                    </div>
                                                ) : null}
                                                {(item.status ===
                                                    SALES_ORDERS_PAYMENT_STATUS.PAID ||
                                                    item.status ===
                                                        SALES_ORDERS_PAYMENT_STATUS.PENDING) && (
                                                    <Button
                                                        color="danger"
                                                        className="inner"
                                                        style={{
                                                            paddingTop:
                                                                "0.20rem",
                                                            paddingBottom:
                                                                "0.20rem",
                                                            paddingLeft:
                                                                "0.50rem",
                                                            paddingRight:
                                                                "0.50rem",
                                                        }}
                                                        onClick={() =>
                                                            openCancellationModal(
                                                                item
                                                            )
                                                        }
                                                        disabled={
                                                            pageData.order_data
                                                                .status !==
                                                                SALES_ORDER_STATUS.AWAITING_PAYMENT &&
                                                            pageData.order_data
                                                                .status !==
                                                                SALES_ORDER_STATUS.PAID &&
                                                            pageData.order_data
                                                                .status !==
                                                                SALES_ORDER_STATUS.PARTIALLY_PAID
                                                        }
                                                    >
                                                        <i
                                                            className="fas fa-times-circle"
                                                            id={
                                                                "cancelpaytooltip" +
                                                                item.id
                                                            }
                                                        ></i>
                                                        <UncontrolledTooltip
                                                            placement="top"
                                                            target={
                                                                "cancelpaytooltip" +
                                                                item.id
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    "red",
                                                                color: "white",
                                                            }}
                                                        >
                                                            Cancelar Pagamento
                                                        </UncontrolledTooltip>
                                                    </Button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </>
                ) : null}

                {!pageData.onlyView && paymentActiveTab != "clean" ? (
                    <div>
                        <hr></hr>
                        <h5 className="modal-title  mb-2" id="myModalLabel">
                            Pagador
                        </h5>
                        <Row>
                            <Col sm="6">
                                <Label>Nome do Pagador</Label>
                                <FastField
                                    name="payer_name"
                                    component={TextInput}
                                    disabled={pageData.onlyView ? true : false}
                                    value={values.payer_name}
                                />
                            </Col>
                            <Col sm="6">
                                <Label>CPF do Pagador</Label>
                                <FastField
                                    name="payer_cpf"
                                    component={TextInput}
                                    value={values.payer_cpf}
                                />
                            </Col>
                        </Row>
                    </div>
                ) : null}

                {!pageData.onlyView ? (
                    <>
                        <hr></hr>

                        {pageControl.canUseTef ? (
                            <Row className="mt-4 mb-2">
                                <Col sm="6">
                                    <h5
                                        className="modal-title "
                                        id="myModalLabel"
                                    >
                                        Realizar Pagamento
                                    </h5>
                                </Col>

                                <Col sm="6" className="d-flex flex-row-reverse">
                                    <div className="custom-control custom-switch custom-switch-md">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input checkbox"
                                            id="checkbox_tef"
                                            checked={pageControl.useTef}
                                            onChange={e => {
                                                setPageControl({
                                                    ...pageControl,
                                                    ...{
                                                        useTef: !pageControl.useTef,
                                                    },
                                                })
                                            }}
                                        />
                                        <label
                                            className="custom-control-label"
                                            htmlFor="checkbox_tef"
                                        >
                                            Recebimento Eletrônico
                                        </label>
                                    </div>
                                </Col>
                            </Row>
                        ) : (
                            <></>
                        )}

                        <Nav tabs className="nav-tabs-custom">
                            <NavItem>
                                <NavLink
                                    style={{
                                        cursor: "pointer",
                                    }}
                                    className={classnames({
                                        active: paymentActiveTab === "clean",
                                    })}
                                    onClick={() => {
                                        setPaymentActiveTab("clean")
                                    }}
                                >
                                    Formas de Pagamento
                                </NavLink>
                            </NavItem>

                            {paymentActiveTab === "money" ? (
                                <NavItem>
                                    <NavLink
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        className={classnames({
                                            active: true,
                                        })}
                                    >
                                        Dinheiro
                                    </NavLink>
                                </NavItem>
                            ) : null}
                            {paymentActiveTab === "PIX" &&
                            pageControl.useTef ? (
                                <NavItem>
                                    <NavLink
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        className={classnames({
                                            active: true,
                                        })}
                                    >
                                        PIX
                                    </NavLink>
                                </NavItem>
                            ) : null}
                            {paymentActiveTab === "CC" ? (
                                <NavItem>
                                    <NavLink
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        className={classnames({
                                            active: true,
                                        })}
                                    >
                                        Cartão de Crédito
                                    </NavLink>
                                </NavItem>
                            ) : null}
                            {paymentActiveTab === "CD" ? (
                                <NavItem>
                                    <NavLink
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        className={classnames({
                                            active: true,
                                        })}
                                    >
                                        Cartão de Débito
                                    </NavLink>
                                </NavItem>
                            ) : paymentActiveTab === "financial" ? (
                                <NavItem>
                                    <NavLink
                                        style={{
                                            cursor: "pointer",
                                        }}
                                        className={classnames({
                                            active: true,
                                        })}
                                    >
                                        Financeira
                                    </NavLink>
                                </NavItem>
                            ) : null}
                        </Nav>

                        <TabContent activeTab={paymentActiveTab}>
                            <TabPane tabId={"clean"}>
                                <Row className="mt-2">
                                    <Col sm="6" className="mt-3">
                                        <Button
                                            className="form-control btn btn-info waves-effect waves-light"
                                            onClick={() => {
                                                setPaymentActiveTab("money")
                                            }}
                                        >
                                            Dinheiro
                                        </Button>
                                    </Col>
                                    {pageControl.useTef ? (
                                        <Col sm="6" className="mt-3">
                                            {/* <Button id="neonShadow" type="button" onClick={() => {
                                                    setPaymentActiveTab("PIX")
                                                }}><span>PIX</span></Button> */}
                                            <Button
                                                className="form-control btn btn-info waves-effect waves-light"
                                                type="button"
                                                onClick={() => {
                                                    setPaymentActiveTab("PIX")
                                                }}
                                            >
                                                <span>
                                                    {" "}
                                                    <form class="form-floating">
                                                        <small
                                                            for="floatingInputInvalid"
                                                            style={{
                                                                position:
                                                                    "absolute",
                                                            }}
                                                            className="ml-3 animationsmalltag"
                                                        >
                                                            Disponível
                                                        </small>
                                                    </form>
                                                    PIX
                                                </span>
                                            </Button>
                                        </Col>
                                    ) : null}
                                </Row>
                                <Row>
                                    <Col sm="6" className="mt-3">
                                        <Button
                                            className="form-control btn btn-info waves-effect waves-light"
                                            onClick={() => {
                                                setPaymentActiveTab("CC")
                                            }}
                                        >
                                            Cartão de Crédito
                                        </Button>
                                    </Col>
                                    <Col sm="6" className="mt-3">
                                        <Button
                                            className="form-control btn btn-info waves-effect waves-light"
                                            onClick={() => {
                                                setPaymentActiveTab("CD")
                                            }}
                                        >
                                            Cartão de Débito
                                        </Button>
                                    </Col>
                                </Row>
                                {controlOdontologicData.haveToControl ? (
                                    <Row className="mt-3">
                                        <Col sm="6">
                                            <Button
                                                className="form-control btn btn-info waves-effect waves-light"
                                                onClick={() => {
                                                    setPaymentActiveTab(
                                                        "financial"
                                                    )
                                                    setFieldValue("financial", {
                                                        label: "Flip",
                                                        value:
                                                            FINANCIAL_PAYMENT_METHODS.FLIP,
                                                    })
                                                }}
                                            >
                                                Financeira
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : null}
                            </TabPane>
                            <TabPane tabId="money">
                                <Row>
                                    <Col sm="4">
                                        <CardText className="mt-4 ">
                                            <Label>Valor</Label>
                                            <FastField
                                                name={`money_value`}
                                                defaultValue={
                                                    values.money_value
                                                }
                                                displayType={"input"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowEmptyFormatting={true}
                                                prefix={"R$ "}
                                                className={
                                                    controlOdontologicData?.haveToControl
                                                        ? "form-control bg-light"
                                                        : "form-control"
                                                }
                                                disabled={
                                                    controlOdontologicData?.haveToControl
                                                }
                                                component={CurrencyInput}
                                            />
                                        </CardText>
                                    </Col>
                                </Row>
                            </TabPane>
                            {pageControl.useTef ? (
                                <TabPane tabId="PIX">
                                    <Row>
                                        <Col sm="4">
                                            <CardText className="mt-4 ">
                                                <Label>Valor</Label>
                                                <FastField
                                                    name={`pix_value`}
                                                    defaultValue={
                                                        values.pix_value
                                                    }
                                                    displayType={"input"}
                                                    thousandSeparator={"."}
                                                    decimalSeparator={","}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    allowEmptyFormatting={true}
                                                    prefix={"R$ "}
                                                    component={CurrencyInput}
                                                    className={
                                                        controlOdontologicData.haveToControl
                                                            ? "form-control bg-light"
                                                            : "form-control"
                                                    }
                                                    disabled={
                                                        controlOdontologicData.haveToControl
                                                    }
                                                />
                                            </CardText>
                                        </Col>
                                    </Row>
                                </TabPane>
                            ) : null}
                            <TabPane tabId="CC">
                                <Row className="mt-4">
                                    <Col sm="4">
                                        <FormGroup>
                                            <Label>Valor</Label>
                                            <FastField
                                                name={`cc_value`}
                                                defaultValue={values.cc_value}
                                                displayType={"input"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowEmptyFormatting={true}
                                                prefix={"R$ "}
                                                className={
                                                    controlOdontologicData.haveToControl
                                                        ? "form-control bg-light"
                                                        : "form-control"
                                                }
                                                component={CurrencyInput}
                                                disabled={
                                                    controlOdontologicData.haveToControl
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Label>Parcelamento</Label>
                                            <FastField
                                                name={`cc_parcels`}
                                                placeholder="Selecione..."
                                                classNamePrefix="select2-selection"
                                                options={optionsParcels}
                                                isMulti={false}
                                                component={ReactSelectInput}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {pageControl.useTef && pageControl?.electronic_vittapag_payment_status &&
                                    PAYMENT_FORMS_ELECTRONIC.includes("CC") ? (
                                        <></>
                                    ) : (
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label>Bandeira Cartão</Label>
                                                <FastField
                                                    name={`cc_card_flag`}
                                                    placeholder="Selecione..."
                                                    classNamePrefix="select2-selection"
                                                    options={optionsFlags}
                                                    isMulti={false}
                                                    component={ReactSelectInput}
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                                {pageControl.useTef && pageControl?.electronic_vittapag_payment_status &&
                                PAYMENT_FORMS_ELECTRONIC.includes("CC") ? (
                                    <></>
                                ) : (
                                    <Row>
                                        <Col sm="5">
                                            <FormGroup>
                                                <Label>Doc/NSU</Label>
                                                <FastField
                                                    name={`cc_nsu_code`}
                                                    defaultValue={
                                                        values.cc_nsu_code
                                                    }
                                                    component={TextInput}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                            </TabPane>
                            <TabPane tabId="CD">
                                <Row>
                                    <Col sm="4">
                                        <FormGroup className="mt-4">
                                            <Label>Valor</Label>
                                            <FastField
                                                name={`cd_value`}
                                                defaultValue={values.cd_value}
                                                displayType={"input"}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowEmptyFormatting={true}
                                                prefix={"R$ "}
                                                className={
                                                    controlOdontologicData.haveToControl
                                                        ? "form-control bg-light"
                                                        : "form-control"
                                                }
                                                component={CurrencyInput}
                                                disabled={
                                                    controlOdontologicData.haveToControl
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    {pageControl.useTef && pageControl?.electronic_vittapag_payment_status &&
                                    PAYMENT_FORMS_ELECTRONIC.includes("CD") ? (
                                        <></>
                                    ) : (
                                        <Col sm="3">
                                            <FormGroup className="mt-4">
                                                <Label>Bandeira Cartão</Label>
                                                <FastField
                                                    name={`cd_card_flag`}
                                                    placeholder="Selecione..."
                                                    classNamePrefix="select2-selection"
                                                    options={optionsFlags}
                                                    isMulti={false}
                                                    component={ReactSelectInput}
                                                />
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                                {pageControl.useTef && pageControl?.electronic_vittapag_payment_status &&
                                PAYMENT_FORMS_ELECTRONIC.includes("CD") ? (
                                    <></>
                                ) : (
                                    <Row>
                                        <Col sm="5" className="mt-4">
                                            <FormGroup>
                                                <Label>Doc/NSU</Label>
                                                <FastField
                                                    name={`cd_nsu_code`}
                                                    defaultValue={
                                                        values.cd_nsu_code
                                                    }
                                                    component={TextInput}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                            </TabPane>
                            <TabPane tabId="financial">
                                <Row>
                                    <Col sm="12">
                                        <Row className="mt-3">
                                            <Col sm="4">
                                                {" "}
                                                <FormGroup>
                                                    <Label>Valor</Label>
                                                    <FastField
                                                        name={`financial_value`}
                                                        defaultValue={
                                                            values.financial_value
                                                        }
                                                        displayType={"input"}
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        allowEmptyFormatting={
                                                            true
                                                        }
                                                        prefix={"R$ "}
                                                        className={
                                                            controlOdontologicData.haveToControl
                                                                ? "form-control bg-light"
                                                                : "form-control"
                                                        }
                                                        component={
                                                            CurrencyInput
                                                        }
                                                        disabled={
                                                            controlOdontologicData.haveToControl
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="5">
                                                {" "}
                                                <FormGroup className="">
                                                    <Label>Financeira</Label>
                                                    <Field
                                                        name={`financial`}
                                                        placeholder="Selecione..."
                                                        classNamePrefix="select2-selection"
                                                        options={[
                                                            {
                                                                value:
                                                                    FINANCIAL_PAYMENT_METHODS.FLIP,
                                                                label: "Flip",
                                                            },
                                                        ]}
                                                        value={{
                                                            label: "Flip",
                                                            value:
                                                                FINANCIAL_PAYMENT_METHODS.FLIP,
                                                        }}
                                                        isMulti={false}
                                                        component={
                                                            ReactSelectInput
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>

                        <br />

                        {paymentActiveTab == "clean" ? (
                            <></>
                        ) : (
                            <>
                                <hr></hr>
                                <Row>
                                    <Col lg="4">
                                        <Label>Valor Total</Label>
                                        <Field
                                            name={"total_being_paid"}
                                            displayType={"input"}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            allowEmptyFormatting={true}
                                            prefix={"R$ "}
                                            className="form-control"
                                            formValues={values}
                                            component={TotalValueInput}
                                            disabled
                                        />
                                    </Col>
                                    {controlOdontologicData.haveToControl &&
                                    controlOdontologicData.haveToControlEdit ? (
                                        <>
                                            <Col lg="4">
                                                <Label>Receber Serviços</Label>
                                                <div>
                                                    {" "}
                                                    <ButtonGroup
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <Button
                                                            color="info"
                                                            type="submit"
                                                        >
                                                            Pagar{" "}
                                                            <i class="fas fa-money-bill text-white"></i>
                                                        </Button>
                                                        <Button
                                                            color="danger"
                                                            type="button"
                                                            onClick={() => {
                                                                let data = {
                                                                    ...controlOdontologicData,
                                                                }
                                                                let dataProps = {
                                                                    ...docData,
                                                                }
                                                                dataProps.total_order =
                                                                    values?.total_order -
                                                                    values?.total_paid
                                                                data.modal.show = true
                                                                data.modal.data = dataProps
                                                                setControlOdontologicData(
                                                                    data
                                                                )
                                                            }}
                                                        >
                                                            Editar{" "}
                                                            <i className="fas fa-edit text-white"></i>
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>

                                                {/* <button
                                                onClick={() => {
                                                    let data = {
                                                        ...controlOdontologicData,
                                                    }
                                                    data.modal.show = true
                                                    data.modal.data = docData
                                                    setControlOdontologicData(
                                                        data
                                                    )
                                                }}
                                                type="button"
                                                className="form-control btn btn-primary waves-effect waves-light"
                                            >
                                                Selecionar Serviços
                                            </button> */}
                                            </Col>
                                        </>
                                    ) : controlOdontologicData.haveToControl ? (
                                        <Col lg="4">
                                            <Label>Confirmar Serviços</Label>
                                            <button
                                                onClick={() => {
                                                    let data = {
                                                        ...controlOdontologicData,
                                                    }
                                                    let dataProps = {
                                                        ...docData,
                                                    }
                                                    dataProps.total_order =
                                                        values?.total_order -
                                                        values?.total_paid
                                                    data.modal.show = true
                                                    data.modal.data = dataProps
                                                    setControlOdontologicData(
                                                        data
                                                    )
                                                }}
                                                type="button"
                                                className="form-control btn btn-primary waves-effect waves-light"
                                            >
                                                Selecionar Serviços
                                            </button>
                                        </Col>
                                    ) : (
                                        <Col lg="4">
                                            <Label>Confirmar Pagamento</Label>
                                            <button
                                                type="submit"
                                                className="form-control btn btn-primary waves-effect waves-light"
                                                disabled={showLoadingButtonPay}
                                            >
                                                {showLoadingButtonPay
                                                    ? "Processando solicitação..."
                                                    : "Pagar"}
                                            </button>
                                        </Col>
                                    )}

                                    <Col className="d-flex justify-content-end align-items-end">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="customControlInline"
                                                onChange={changeAutoPrint}
                                                checked={autoPrintReceipt}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor="customControlInline"
                                            >
                                                Imprimir Recibo
                                            </label>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </>
                ) : null}
            </>
        )
    }
    const handleApplyOdontologicItems = (data, setFieldValue, valuesForm) => {
        let dataApplied = { ...controlOdontologicData }
        dataApplied.totalSelectedData = data?.totalSelectedItems
        dataApplied.selectedData = data.selectedItems
        dataApplied.haveToControlEdit = true
        setControlOdontologicData(dataApplied)

        if (paymentActiveTab == "money") {
            setFieldValue("money_value", data?.totalSelectedItems / 100)
        } else if (paymentActiveTab == "PIX") {
            setFieldValue("pix_value", data?.totalSelectedItems / 100)
        } else if (paymentActiveTab == "CC") {
            setFieldValue("cc_value", data?.totalSelectedItems / 100)
        } else if (paymentActiveTab == "CD") {
            setFieldValue("cd_value", data?.totalSelectedItems / 100)
        } else if (paymentActiveTab == "financial") {
            setFieldValue("financial_value", data?.totalSelectedItems / 100)
        }
    }

    const registerCardTransactions = async data => {
        await createPayment(data)
        let auxPageControl = { ...pageControl }
        auxPageControl.operations.create = true
        auxPageControl.lastForceLoadData = new Date().getTime()
        auxPageControl.clearPageOnLoadData = false
        auxPageControl.submiting = false

        setPaymentActiveTab("clean")

        setPageControl(auxPageControl)
    }

    return (
        <Modal isOpen={show} centered={true} size="lg">
            <Formik
                initialValues={pageData.form_data}
                enableReinitialize={true}
                onSubmit={onSubmit}
            >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form>
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Detalhes Financeiros
                            </h5>
                            <button
                                type="button"
                                onClick={() => {
                                    closeModal(pageControl.operations)
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            {pageData.loading
                                ? renderLoading()
                                : renderForm(values, data, setFieldValue)}
                        </div>
                        {showLoadingButtonPay ? null : (
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={() => {
                                        closeModal(pageControl.operations)
                                    }}
                                    className="btn btn-sm btn-info waves-effect"
                                    data-dismiss="modal"
                                >
                                    Fechar
                                </button>
                            </div>
                        )}
                        <HandleSelectItemsToPayModal
                            show={controlOdontologicData.modal.show}
                            data={controlOdontologicData.modal.data}
                            setControlOdontologicData={
                                setControlOdontologicData
                            }
                            onClose={response => {
                                let data = { ...controlOdontologicData }
                                data.modal.show = false
                                data.modal.data = null
                                setControlOdontologicData(data)
                                if (response?.response) {
                                    handleApplyOdontologicItems(
                                        response?.response,
                                        setFieldValue,
                                        values
                                    )
                                }
                            }}
                        />
                    </Form>
                )}
            </Formik>

            <ModalCardPayments
                show={controlModalCardPayments.show}
                data={controlModalCardPayments.data}
                data_form={controlModalCardPayments.data_form}
                setControlModal={setControlModalCardPayments}
                registerCardTransactions={registerCardTransactions}
                action={controlModalCardPayments.action}
                confirmCancelPayment={confirmCancelPayment}
            />

            <CheckPaymentLoading show={controlCheckPaymentLoadingModal.show} />

            <PaymentCancellationModal
                isOpen={isCancellationModalOpen}
                onClose={() => setIsCancellationModalOpen(false)}
                onConfirm={handleConfirmCancellation}
                confirmButtonText={"Confirmar"}
                cancelButtonText={"Fechar"}
            />
        </Modal>
    )
}

export default ModalPayment
